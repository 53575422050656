/* EmojiStyles.module.scss */

.emojiSelect {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.emojiSelectButton {
  font-size: 14px;
  color: transparent;
  background: none;
  border: transparent;
  cursor: pointer;
}

.emojiSelectButtonPressed {
  font-size: 14px;
  color: transparent;
  background: none;
  border: transparent;
  cursor: pointer;
}

.emojiSelectPopover {
  position: absolute;
  z-index: 1000;
  width: 360px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #344050;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  padding: 8px;
}

.emojiSelectPopoverGroups {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.emojiSelectPopoverGroup {
  width: 100%;
  margin-bottom: 16px;
}

.emojiSelectPopoverGroupList {
  display: flex;
  flex-wrap: wrap;
}

.emojiSelectPopoverGroupItem {
  width: 24px;
  height: 24px;
  margin: 8px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}