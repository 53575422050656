///*-----------------------------------------------
//|   Breakpoints
//-----------------------------------------------*/
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1480px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1540px
) !default;

//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/
$white: #fff !default;
$gray-100: #f9fafd !default;
$gray-200: #edf2f9 !default;
$gray-300: #d8e2ef !default;
$gray-400: #b6c1d2 !default;
$gray-500: #9da9bb !default;
$gray-600: #748194 !default;
$gray-700: #5e6e82 !default;
$gray-800: #4d5969 !default;
$gray-900: #344050 !default;
$gray-1000: #232e3c !default;
$gray-1100: #0b1727 !default;
$black: #000 !default;

$grays: () !default;
$grays: map_merge(
  $grays,
  (
    'black': $black,
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
    '1000': $gray-1000,
    '1100': $gray-1100,
    'white': $white
  )
);

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #2c7be5 !default;
$indigo: #727cf5 !default;
$purple: #6b5eae !default;
$pink: #ff679b !default;
$red: #e63757 !default;
$orange: #fd7e14 !default;
$yellow: #f5803e !default;
$green: #00d27a !default;
$teal: #02a8b5 !default;
$cyan: #27bcfd !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$theme-colors: () !default;
$theme-colors: map_merge(
  $theme-colors,
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark
  )
);

.mention {
  color: $light;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background: $primary;
  padding-bottom: 3px;
}

.mentionSuggestions {
  width: 50%;
  font-weight: bold;
  border: 1px solid;
  box-shadow: inset 0px 3px 12px -3px #ababab;
  background: $light;
  color: #ababab;
  border-radius: 15px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  margin: -16px;
  z-index: 999;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntry:active {
  background-color: $light;
  color: $primary;
}

.mentionSuggestionsEntryFocused {
  background-color: $primary;
  color: $light;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: $light;
}
